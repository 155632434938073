import React, { useState } from 'react'
import Transparent from './images/transparent.gif'

export default function SupportLink() {
	const [Socialicon, setSocialicon] = useState(0);

	return (
		<React.Fragment>
			<div class="moreinfo-wrap-front">
				<div id="supportWrap" class="support-wrap-front">
					<div class="support-service-front">

						<a id="support_email" onMouseOver={() => { setSocialicon(0) }} className={`support-mail-front  ${(Socialicon === 0) ? "open" : "null"}`} ><img src={Transparent} title="Email" /></a>


						<a id="support_whatsapp" onMouseOver={() => { setSocialicon(1) }} className={`support-whatsapp-front  ${(Socialicon === 1) ? "open" : "null"}`} ><img src={Transparent} title="WhatsApp" /></a>


						<a id="support_fb" onMouseOver={() => { setSocialicon(2) }} className={`support-fb-front  ${(Socialicon === 2) ? "open" : "null"}`} ><img src={Transparent} title="facebook" /></a>



						<a id="support_skype" onMouseOver={() => { setSocialicon(3) }} className={`support-skype-front  ${(Socialicon === 3) ? "open" : "null"}`}><img src={Transparent} title="Skype" /></a>


						<a id="support_instagram" onMouseOver={() => { setSocialicon(4) }} className={`support-ig-front  ${(Socialicon === 4) ? "open" : "null"}`} ><img src={Transparent} title="Instagram" /></a>

					</div>
					<div class="support-info-front">
						{Socialicon === 0 &&
							<div id="supportDetail_email" className={`support-detail-front ${(Socialicon === 0) ? "open" : "null"}`}><a href="mailto:info@bdtwin">info@bdtwin.live</a></div>
						}
						{Socialicon === 1 &&
							<div id="supportDetail_whatsapp" className={`support-detail-front ${(Socialicon === 1) ? "open" : "null"}`}>
								<a href="http://Wa.me/0000">Wa.me/0000</a>


							</div>}
						{Socialicon === 2 &&
							<div id="supportDetail_telegram" className={`support-detail-front ${(Socialicon === 2) ? "open" : "null"}`}>
								<a href="https://www.facebook.com/groups/bdtwin/" >www.facebook.com/groups/bdtwin/</a>

							</div>}
						{Socialicon === 3 &&
							<div id="supportDetail_skype" className={`support-detail-front ${(Socialicon === 3) ? "open" : "null"}`}>
								<a  >bdtwinInfo</a>
							</div>
						}
						{Socialicon === 4 &&
							<div id="supportDetail_instagram" className={`support-detail-front ${(Socialicon === 4) ? "open" : "null"}`}>
								<a class="ui-link" target="_blank">bdtwinInfo</a>
							</div>}

					</div>
				</div>

				{/* <!-- Power by --> */}
				<div class="power-wrap-front">
					<h3><span>Powered by</span><img src={Transparent} /></h3>


					<p><a target="_blank">Sky Infotech Limited</a> is licensed and regulated by Government of Curacao under license no 365/JAZ Sub-License GLH-OCCHKTW0707072017.</p>
				</div>


				{/* <!-- Browser Support --> */}
				{/* <div class="browser-wrap-front">
		<img src={Transparent}/><br/>
		Our website works best in the newest and last prior version of these browsers: <br/>Google Chrome. Firefox
	</div> */}
			</div>
		</React.Fragment>
	)
}
